exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-latest-js": () => import("./../../../src/pages/latest.js" /* webpackChunkName: "component---src-pages-latest-js" */),
  "component---src-pages-p-kpdb-js": () => import("./../../../src/pages/pKPDB.js" /* webpackChunkName: "component---src-pages-p-kpdb-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-results-js": () => import("./../../../src/pages/results.js" /* webpackChunkName: "component---src-pages-results-js" */),
  "component---src-pages-run-pypka-js": () => import("./../../../src/pages/run-pypka.js" /* webpackChunkName: "component---src-pages-run-pypka-js" */)
}

